module.exports = {
  siteTitle: 'Indian Astrologer and Vastu Specialist', // <title>
  manifestName: 'Creative',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-creative/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Dr. M Viswanatha Raju',
  subHeading: 'Renowned Indian Astrologer with over 30 years of experience',

  // social
  socialLinks: [
    {
      icon: 'fa-linkedin-in',
      name: 'Linkedin',
      url: 'https://linkedin.com/',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/',
    },
    {
      icon: 'fa-facebook-f',
      name: 'Facebook',
      url: 'https://facebook.com/',
    },
  ],
  email: 'mvraju52@yahoo.co.in',
  phone: '+91 94409 45403 / +91 93914 76538',
  address: '6-3-120/6, NPA Colony, Shivarampally, Hyderabad -500 052',
};
